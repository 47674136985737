import { SearchMaskFeatureFlags } from '@webc/meeseeks-ui-sdk/build/types/definitions/components/SearchMaskFeatures';

export const FEATURE_DISTRIBUSION = 'feature.enable_distribusion';
export const FEATURE_ONLY_TRAIN_CITIES = 'feature.train_cities_only';
export const FEATURE_AUTO_UPDATE_DISABLED = 'feature.auto_update_disabled';
export const FEATURE_US_VETERANS_PROMOTED = 'feature.webc_search_us_veterans_promoted';
export const FEATURE_NO_STATIONS_LIMIT = 'feature.webc_search_no_stations_limit';

export const features: Partial<{ [key in SearchMaskFeatureFlags]: boolean }> = {
  [FEATURE_DISTRIBUSION]: true,
  [FEATURE_ONLY_TRAIN_CITIES]: false,
  [FEATURE_AUTO_UPDATE_DISABLED]: false,
  [FEATURE_US_VETERANS_PROMOTED]: false,
  [FEATURE_NO_STATIONS_LIMIT]: false,
};

export const featuresToggledByCookies: {
  [flag in SearchMaskFeatureFlags]?: {
    cookieName: string;
    isEnabled: (cookieValue?: string) => boolean;
  };
} = {
  [FEATURE_DISTRIBUSION]: {
    cookieName: 'search_distribusion',
    isEnabled: (cookieValue?: string) => cookieValue === '1',
  },
  [FEATURE_AUTO_UPDATE_DISABLED]: {
    cookieName: 'search_auto_update_disabled',
    isEnabled: (cookieValue?: string) => cookieValue === '1',
  },
  [FEATURE_US_VETERANS_PROMOTED]: {
    cookieName: 'webc_search_us_veterans_promoted',
    isEnabled: (cookieValue?: string) => cookieValue === '1',
  },
  [FEATURE_NO_STATIONS_LIMIT]: {
    cookieName: 'webc_search_no_stations_limit',
    isEnabled: (cookieValue?: string) => cookieValue === '1',
  },
};
